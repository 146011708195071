<script>
import config from "@/helpers/config"
import niceFunctions from "@/helpers/niceFunctions"
import SvgTransition from '@/components/SvgTransition.vue'
export default {
    components:{ SvgTransition },
    created() {  
        this.$cache.createCache('publicPage', 'public-page/', {id: this.$route.params.id }); 
    },
    props:['windowScroll'],
    data() {
        return {  
            hasScrolledOnce: false
        }
    },
    methods:
    {
        scrollToSection() {
            const scrollDistance = window.innerHeight * 0.5; 
            $("html, body").animate({ scrollTop: scrollDistance });
            this.hasScrolledOnce = true;
        },
        getColor(location)
        {
            return niceFunctions.getColor(this.publicPageColors, location)
        }     
    },
    computed: {
        url() { return config.urlToServer },  
        publicPageColors() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageColors : null },
        publicPage() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPage : null },
        publicPageHome() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageHome : null },
        publicPageImages() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.publicPageImages : null },
        news() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.news : {} },
        customerName() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.customerName : {} },
        settings() { return this.$store.state.publicPage != null ?  this.$store.state.publicPage.settings : null },
        height() { return window.innerHeight + 'px' },
        showNews() {return this.windowScroll > $("#Home-welcome-text").height()+0 }      
    },
    watch:
    {
        windowScroll(newvalue)
        {
            if(newvalue > 100)
            {
                this.hasScrolledOnce = true;
            }
        }
    }
}
</script>
<template>
   <section v-fade-in v-if="publicPage != null && publicPageHome != null" class="public-page">
        <div class="top-public" :style="{ 'height': height }" v-background="publicPageImages.find(x => x.location == 'home')">
         <section style="padding-top:200px;">
            <div :style="{ 'color': getColor('welcome_text') + ' !important' }" class="blur-background" style="width:100%;text-transform:uppercase">
                Välkommen till <br>
                {{customerName}}
            </div>
            <p :style="{ 'color': getColor('welcome_text') }" @click="scrollToSection()" :class="hasScrolledOnce ? 'Home-fade-out' : ''" class="blur-background">Läs mer</p>
        </section>
        <SvgTransition :publicPage="publicPage"></SvgTransition>  
        </div>
        <Transition name="Home-welcome-text">
            <div class="text-section" id="Home-welcome-text" v-if="hasScrolledOnce" v-html="this.$adaptHtml.adaptHtml(publicPageHome.welcome_text, getColor('header_color_on_white_bg'), getColor('background_color'))"></div>
        </Transition>
        <div class="position-relative" v-if="showNews">
            <SvgTransition :publicPage="publicPage" :color="getColor('background_color')"></SvgTransition>   
        </div>
        <div :style="{ 'background-color': getColor('background_color'), 'min-height': '500px' }" class="position-relative">
            <Transition  name="Home-welcome-text">
                <div class="text-section" v-if="showNews">
                    <h1 :style="{ 'color': getColor('header_color') }">{{settings.News.singular}}</h1>
                    <div v-for="newsItem in news" class="Home-notification-item">   
                        <div v-html="newsItem.message"></div>
                        <div v-for="image in newsItem.images">
                            <img :src="url + 'uploads/'+ image.customer_id+'/images/'+ image.name"  style="width: 100%" />  
                        </div>              
                        <div style="margin-top:10px;">
                            <i>/{{ newsItem.created_by_user.first_name }}
                                {{ newsItem.created_by_user.last_name }},  
                                <span v-date="{ date: newsItem.created_date, type: 'dateInLetters' }"></span>  
                            </i>
                        </div>    
                    </div>
                </div>
            </Transition>
        </div>
   </section>
</template>
<style scoped>
    .Home-bg{
        padding-top: 100px;
        height: 800px;
        width: 100%;       
    }

    .Home-notification-item
    {
        padding: 15px;
        margin: 5px 20px 0px 0px;
        margin-bottom: 10px;
        display: inline-block;
        width: calc(50% - 50px);
        vertical-align: top;
        border-top: 1px solid #ddd;
    }

    .Home-notification-item i{
        font-size: 14px;
    }
    .top-public section{
        text-align: center;
        height:100%;
        display:flex;
        align-items:start;
    }
    .top-public section div{
       
        font-family: "Fredoka", sans-serif; 
        font-weight: 600;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        width: auto;
        font-size: 40pt;
        padding: 20px 0px;
        letter-spacing: 2px;
    }

    .top-public p{
        position: absolute;
        bottom: 20px;
        font-family: "Fredoka", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        background-color: rgba(255,255,255,0.4);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        width: 160px;
        right: 0px;
        left: 0px;
        margin: auto;
        font-size: 15pt;
        padding:20px;
        cursor: pointer;
    }

    .Home-fade-out
    {
        opacity: 0;
        transition: opacity 1s;
    }
    
    .Home-fade-in
    {
        opacity: 1;
        transition: opacity 1s;
    }


    .Home-welcome-text-enter-active,
    .Home-welcome-text-leave-active {
    transition: opacity 2s ease;
    }

    .Home-welcome-text-enter-from,
    .Home-welcome-text-leave-to {
    opacity: 0;
    }

@media only screen and (max-width: 900px) {
    .top-public section div{
    
       font-size: 18pt;
    }
    .top-public section p{
    
       font-size: 14pt;
    }

    .Home-notification-item{
        width: calc(100% - 40px);
    }
}
</style>